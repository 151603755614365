// FONTS
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;700&display=swap');
// font-family: 'Nunito Sans', sans-serif; // MADE
// font-family: 'Teko', sans-serif; // FOUNDRY

// -- VARIABLES
// - COLORS
$purple: #18102D;
$green: #3EFFC8;
$red: #E25A66;
$white: #FFFFFF;
$whiteDarker: #F5F5F5;
$gray: #D9D9D9;


// ------------------------
// DEFAULT - WIDE DESKTOP >= 1440px
// 16 blocks de 90px subdivisé en 2 (45px * 2 = 90px : 90px * 16 = 1440) OU 45 * 32 = 1440px
$grid-block-size: 45px; // DESKTOP SIZE by DEFAULT
$grid-container-default: 1440px;
// -- font size
$font-title-size: 40px;
$font-large-paragraph-size: 28px;
$font-paragraph-size: 22px;
$font-small-paragraph-size: 16px;
$font-tiny-paragraph-size: 12px;

body{
    margin: 0;
    padding: 0;
    background-color: $whiteDarker;
    user-select: none;
}

.loading-screen{
    overflow: hidden;
    z-index: 9999;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $red;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .pulsate-bck {
        -webkit-animation: pulsate-bck 1s ease-in-out infinite both;
                animation: pulsate-bck 1s ease-in-out infinite both;
    }
    

    img{
        width: 160px;
        height: 160px;
    }

    @-webkit-keyframes pulsate-bck {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        50% {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes pulsate-bck {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        50% {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
    }
      
    span{
        padding-top: 40px;
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 700;
        font-size: $font-paragraph-size;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        color: lighten($red, 6%);
    }
}

.show-loading{
    display: flex;
}

.hide-loading{
    display:none;
}

.container{
    position: relative;
    max-width: $grid-container-default;
    background-color: $white;
    margin: 0 auto;

    .content{
        display: flex;
        align-items: flex-start;
        height: 100vh;
        margin: 0;
        //background-color: $dev;
    }
}

// UI
// -- TOGGLE LANG
.lang-toggle-desk{
    z-index: 999;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $grid-block-size;
    height: $grid-block-size;
    background-color: $purple;
    top: $grid-block-size;
    right: $grid-block-size;
    border-radius: 50%;
    transition-duration: .25s;
    cursor: pointer;

    &:hover{
        background-color: $green;

        span{
            color: $purple;
        }
    }

    span{
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 400;
        font-size: $font-small-paragraph-size;
        color: white;
    }
}

.lang-toggle-mobile{
    z-index: 999;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: $purple;
    top: 19px;
    right: 19px;
    border-radius: 50%;
    transition-duration: .25s;
    cursor: pointer;

    span{
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 400;
        font-size: $font-tiny-paragraph-size;
        color: white;
    }
}
// -- HEADER
header{
    //visibility: hidden;
    position: fixed;
    width: auto;
    background-color: $white;
    z-index: 999;

    .logo{
        display: flex;
        align-items: center;
        align-content: center;
        height: $grid-block-size * 2;

        img{
            height: $grid-block-size;
            margin: 0 $grid-block-size;            
        }
    }
}

// -- SIDE PART contenant notre systeme de navigation
.side-col{
    position: fixed;
    bottom: 0;
    background-color: $red;
    width: $grid-block-size * 2;
    height: $grid-block-size * 8;
    z-index: 998;

    .menu{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;

        .menu-icon{
            display: flex;
            justify-content: center;
            align-items: center;
            height: $grid-block-size * 2;
            width: $grid-block-size * 2;
            background-color: $purple;
            cursor: pointer;

            .icon{
                width: 30px;
                height: 30px;
                color: $green;
            }
            .icon-close{
                width: 28px;
                height: 28px;
                color: $red;
            }
        }

        .menu-info{
            display: flex;
            justify-content: center;
            align-items: center;
            height: $grid-block-size * 4;
            width: $grid-block-size * 2;
            background-color: $whiteDarker;

            span{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
                font-size: $font-small-paragraph-size;
                color: $purple;
                writing-mode: vertical-rl;
            }
        }

        // SCROLL ANIMATED ICON
        .icon-scroll,
        .icon-scroll::before{
            position: absolute;
            left: 50%;
        }

        .icon-scroll {
            width: 30px;
            height: 50px;
            margin-left: -15px;
            top: 50%;
            margin-top: -20px;
            box-shadow: inset 0 0 0 2px $green;
            border-radius: 20px;
        }

        .icon-scroll:before{
            content: '';
            width: 8px;
            height: 8px;
            background: $green;
            margin-left: -4px;
            top: 8px;
            border-radius: 4px;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-name: scroll;
        }

        @keyframes scroll{
        0%{
            opacity: 1;
        }

        100%{
            opacity: 0;
            transform: translateY(40px);
            }
        }

        .menu-scroll{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: $grid-block-size * 2;
            width: $grid-block-size * 2;
            background-color: $white;

            .icon{
                pointer-events: none;
                width: 30px;
                height: 30px;
                color: $purple;
            }
        }
    }

    .menu-deployed{
        position: absolute;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        //opacity: 0;
        bottom: - $grid-block-size * 8;
        left: $grid-block-size * 2;
        width: $grid-block-size * 8;
        height: $grid-block-size * 8;
        background-color: rgba($color: $purple, $alpha: .95);
        flex-direction: column;

        span{
            height: $grid-block-size;
            line-height: $grid-block-size;
            text-align: center;
            color: $white;
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 400;
            font-size: $font-large-paragraph-size;
            background-color:transparent;
            cursor: pointer;
            transition-duration: .25s;
            

            &:hover{
                background-color: $green;
                color: $purple;
            }

            &:first-child{
                margin-top: $grid-block-size;
            }
        }
    }
}

// -- SOCIAL ASIDE BAR
.social-col{
    position: relative;
    width: $grid-block-size * 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;

    .icon-container{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: $grid-block-size;

        .icon-block{
            
            margin: 5px 0;
            height: $grid-block-size;
            width: $grid-block-size;
            cursor: pointer;

            &.outline{ // class utile a l'icon 'Threads' diffférente du lot initial
                border: 2px solid $gray;
                border-radius: 50%;
                box-sizing: border-box;

                &:hover{
                    border-color: $green;
                }
                .icon{
                    margin: 6px
                }
            }
            
            .icon div svg{
                transition-duration: .25s;
                fill: $gray;
            }

            &:hover{
                .icon div svg{
                    fill:$green;
                }
            }
        }
    }
}

// TEXT HOME GWARDIAN
.home-gwardian-text{
    position: absolute;
    bottom: 0;
    right: 0;
    width: $grid-block-size * 2;
    height: $grid-block-size;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color:yellow;
}

// ACCUEIL
.home-intro-container{
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    width: $grid-block-size * 14;
    margin-top: $grid-block-size * 1;
    margin-left: $grid-block-size * 3;

    .home-intro-text{
        position: relative;
        padding: 0;
        margin: 0;

        h1{
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 700;
            color: $purple;
            font-size: $font-title-size;
            line-height: 1;
            text-align: center;
            margin-bottom: $grid-block-size;
        }
    
        p{
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 200;
            font-size: $font-paragraph-size;
            text-align: center;
            line-height: 1.2;
            color: $purple;
            padding-left: $grid-block-size;
            padding-right: $grid-block-size;

            span{
                font-weight: 400;
            }
        }
    }

    // PRES TROPHY ACCUEIL
    .home-intro-trophy{
        position: relative;
        width: $grid-block-size * 14;
        height: $grid-block-size * 5;
        padding: 0;
        margin:0;

        .trophy-title{
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: $grid-block-size * 7;
            height: $grid-block-size;
            left: $grid-block-size * 1;
            background-color: $white;

            h3{
                font-family: 'Nunito Sans', sans-serif;
                font-family: 700;
                font-size: $font-small-paragraph-size;
                text-align: left;
                color: $purple;
            }
        }

        .trophy-container{
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: $grid-block-size * 13;
            height: $grid-block-size;
            left: $grid-block-size * 1;
            background-color: $whiteDarker;
            border-bottom: 1px solid $white;            

            .trophy-icon{
                display: flex;
                justify-content: center;
                align-items: center;
                width: $grid-block-size;
                height: $grid-block-size;
                background-color: $white;

                img{
                    max-width: 30px;
                }
            }

            &:hover{
                .trophy-icon{
                    transition-duration: .5s;
                    background-color: $green;
                }
            }

            .trophy-info{
                width: 100%;

                span{
                    display: block;
                    align-self: center;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 400;
                    font-size: $font-small-paragraph-size;
                    color: $purple;
                    margin-left: 12px;
                }
            }
        }

        .trophy-cta{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: $grid-block-size * 4;
            height: $grid-block-size;
            margin-left: $grid-block-size * 10;
            background-color: $green;
            cursor: pointer;

            span{
                width: $grid-block-size * 3;
                align-self: center;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
                font-size: $font-tiny-paragraph-size;
                text-align: center;
                color: $purple;
            }

            .trophy-cta-icon{
                display: flex;
                justify-content: center;
                align-items: center;
                width: $grid-block-size;
                height: $grid-block-size;
                background-color: $whiteDarker;

                .icon{
                    width: 24px;
                    height: 24px;
                    color: $purple;
                }
            }

            &:hover{
                .trophy-cta-icon{
                    transition-duration: .5s;
                    background-color: $purple;
    
                    .icon{
                        color: $green;
                    }
                }
            }
        }
    }  
}

// -- GWARDIAN FRONT - HOME
.home-gwardian{
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    width: $grid-block-size * 12;
    //height: 100vh;
    margin-top: $grid-block-size;
    margin-left: 0;
    z-index: 10;
    
    img{
        max-width: 100%;
        padding: 0;
        margin: 0;
    }
}

// PRESENTATION
.pres-container{
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $purple;
    width: $grid-block-size * 30;
    margin-left: $grid-block-size * 2;
    z-index: 100;

    h1{
        text-align: center;
        font-size: $font-large-paragraph-size;
        color: $green;
        margin-top: $grid-block-size * 2;
        margin-bottom: $font-paragraph-size;
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 700;
    }

    h2{
        text-align: center;
        font-size: $font-large-paragraph-size;
        color: $green;
        margin-top: $font-paragraph-size * 2;
        margin-bottom: $grid-block-size;
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 700;
    }

    p{
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 200;
        font-size: $font-paragraph-size;
        text-align: center;
        line-height: 1.1;
        color: $white;
        padding-top: $font-paragraph-size;
        padding-bottom: $font-paragraph-size;
        padding-left: $grid-block-size * 6;
        padding-right: $grid-block-size * 6;

        span{
            color: $red;
            font-weight: 400;
        }
    }


    .pres-partenaires-logos{
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: $grid-block-size * 2;

        &:last-child{
            margin-bottom: $grid-block-size;
        }

        &.marged{ // class pour centré si 2 logo sur une lignes au lieu de 3
            margin: 0 $grid-block-size * 4;
            margin-bottom: $grid-block-size;
        }
    
        a {
            cursor: pointer;
            text-decoration: none;
            outline: none;

            img{
                width: $grid-block-size * 3;

                &.block-l{
                    width: $grid-block-size * 4;
                }
                &.block-S{
                    width: $grid-block-size * 2;
                }
            }
        }
    }

    .pres-partenaires-infos{
        position: relative;
        display: flex;
        display: flex;
        justify-content: space-around;
        height: 0;
        background-color: $red;
        
        .info{
            opacity: 0;
            position: relative;
            padding: $font-large-paragraph-size;

            p{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
                font-size: $font-small-paragraph-size;
                text-align: left;
                line-height: 1.1;
                color: $white;
                padding: $font-small-paragraph-size;

                span{
                    color: $white;
                    font-weight: 700;
                    //background-color: $whiteDarker;
                }
            }
        }
    }
}

.pres-behind{
    position: absolute;
    width: $grid-block-size * 30;
    height: $grid-block-size * 15;
    top: $grid-block-size;
    left: $grid-block-size;
    background-color: $red;
    z-index: 50;
}

// COUNTERS
.counter-container{
    position: relative;
    width: $grid-block-size * 30;
    margin-left: $grid-block-size * 2;
    padding: $grid-block-size 0;
    background-color: $white;
    z-index: 60;

    h3{
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 700;
        font-size: $font-paragraph-size;
        text-align: center;
        color: $gray;
        padding-bottom: $grid-block-size;
    }

    .counter-blocks-container{
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        z-index: 100;
    
        .counter-block{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;

            span.text{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
                font-size: $font-large-paragraph-size;
                text-align: center;
                color: $purple;
            }

            span.numbers{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 700;
                font-size: $font-large-paragraph-size * 2;
                text-align: center;
                color: $green;
            }
        }
    }
}


// SCORES
.scores-container{
    position: relative;
    min-height: $grid-block-size * 22;

    .scores-board-container{
        position: relative;
        margin-left: $grid-block-size * 12;
        //margin-top: $grid-block-size * 4;
        background-color: transparent;
        width: $grid-block-size * 18;
        height:  $grid-block-size * 14;
        z-index: 90;

        .scores-face-everything{
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            height: $grid-block-size * 3;
            width: 100%;
            background-color: transparent;

            img{
                max-height: $grid-block-size * 1;
                padding: $grid-block-size;
                margin: 0;
            }
        }

        // BORDER BOARD BEHIND
        .score-board-behind{
            position: absolute;
            top: $grid-block-size * 4;
            left: 0;
            width: 100%;
            height: $grid-block-size * 10;
            background-color: rgba($color: $purple, $alpha: .8);
        }
    }

    // SCORE BOARD LINE
    .score-line{
        position: relative;
        display: flex;
        height: $grid-block-size;
        margin-left: $grid-block-size;
        background-color: $white;
        border-bottom: 1px solid $purple;
        box-sizing:border-box;
        -webkit-box-sizing:border-box;
        z-index: 100;
        transition-duration: .25s;

        &:last-child{
            border-bottom: 0 none;
        }

        &:hover{
            background-color: $green;
        }

        .score-line-logo{
            display: flex;
            justify-content: center;
            align-items: center;
            width: $grid-block-size * 2;
            background-color: $purple;
            background-size: 70%;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .apex{
            background-image: url('../png/games_logo_apex.png');
        }

        .nba{
            background-image: url('../png/games_logo_nba.png');
        }

        .ft{
            background-image: url('../png/games_logo_fortnite.png');
        }

        .tm{
            background-image: url('../png/games_logo_tm.png');
        }

        .lol{
            background-image: url('../png/games_logo_lol.png');
        }

        .tf{
            background-image: url('../png/games_logo_teamfight.png');
        }

        .r6{
            background-image: url('../png/games_logo_rainbow.png');
        }

        .score-line-date{
            display: flex;
            justify-content: center;
            align-items: center;
            width: $grid-block-size * 2;
            padding-left: 4px;

            span{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 700;
                font-size: $font-tiny-paragraph-size;
                color: $purple;
                padding-top: 3px;
                padding-left: 4px;
            }

            .icon{
                width: $font-small-paragraph-size;
                height: $font-small-paragraph-size;
                color: $gray;
            }

            
        }

        .score-line-titre{
            display: flex;
            justify-content: center;
            align-items: center;
            width: $grid-block-size * 6;

            span{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 700;
                font-size: $font-tiny-paragraph-size;
                color: $purple;
            }
        }

        .score-line-info{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: $grid-block-size * 5;
            background-color: $whiteDarker;

            span{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 200;
                font-size: $font-tiny-paragraph-size;
                color: $purple;
            }
        }

        .score-line-top{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: $grid-block-size * 2;

            span{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 700;
                font-size: $font-tiny-paragraph-size;
                text-align: center;
            }

            span.good{
                color: $purple;
                text-transform: uppercase;
            }

            span.bad{
                color: $white;
                text-transform: uppercase;
            }
        }

        .good{
            background-color: $green;
        }

        .bad{
            background-color: $red;
        }
    }

    // TITLE LAST RESULT
    .side-title{
        position: absolute;
        top: $grid-block-size * 2;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $whiteDarker;
        height: $grid-block-size * 2;
        width: $grid-block-size * 6;
        z-index: 60;
       
            h3{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 200;
                font-size: $font-paragraph-size;
                text-align: center;
                color: $purple;
            }
    }

    // GWARDIAN
    .scores-gwardian{
        position: absolute;
        top: -$grid-block-size;
        left: $grid-block-size * 4;
        width: $grid-block-size * 12;
        height: auto;
        display: flex;
        align-items: flex-start;
        z-index: 60;

        img{
            padding: 0;
            margin: 0;
        }
    }

    // RED BLOCK BEHIND GWARDIAN
    .scores-behind{
        position: absolute;
        width: $grid-block-size * 12;
        height: $grid-block-size * 11;
        top: $grid-block-size * 8;
        left: $grid-block-size * 3;
        background-color: $red;
        z-index: 50;
    }

    // BUTTON SCORE BOARD
    .score-button{
        position: absolute;
        bottom: 0;
        right: 0;
        width: $grid-block-size * 5;
        height: $grid-block-size;
        background-color: $purple;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        cursor: pointer;

        span.score-button-name{
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 700;
            font-size: $font-tiny-paragraph-size;
            text-align: center;
            color: $green;
            width: $grid-block-size * 4;
        }

        span.score-button-icon{
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 700;
            font-size: $font-tiny-paragraph-size;
            text-align: center;
            color: $green;
            width: $grid-block-size * 1;

            .icon{
                width: 28px;
                height: 28px;
                color: $green;
            }
        }
    }
}

// MERCHANDISING
.merch-container{
    position: relative;
    display: flex;
    min-height: $grid-block-size * 20;
    z-index: 100;

    .merch-products-container{
        position: relative;
        width: $grid-block-size * 18;
        margin-left: $grid-block-size;
        height: 100%;
        z-index: 50;

        .merch-cta-shop{
            position: absolute;
            top: 0;
            left: $grid-block-size;
            display: flex;
            justify-content: center;
            align-items: center;
            width: $grid-block-size * 5;
            height: $grid-block-size * 2;
            background-color: rgba($color: $white, $alpha: .6);
            cursor: pointer;
            border: 2px solid $purple;
            box-sizing:border-box;
            -webkit-box-sizing:border-box;
            transition-duration: .25s;
    
            span{
                width: $grid-block-size * 4;
                align-self: center;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
                font-size: $font-paragraph-size;
                text-align: center;
                color: $purple;
            }
    
            .merch-cta-shop-icon{
                display: flex;
                justify-content: center;
                align-items: center;
                width: $grid-block-size * 2;
                height: $grid-block-size * 2;
    
                .icon{
                    width: 40px;
                    height: 40px;
                    color: $purple;
                }
            }
    
            &:hover{

                background-color: rgba($color: $purple, $alpha: 1);
    
                span{
                    color:$white;
                }
    
                .merch-cta-shop-icon{
    
                    .icon{
                        color: $white;
                    }
                }
            }
        }

        .merch-small{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: $grid-block-size * 6;
            height: $grid-block-size * 6;
            background-color: $white;
            z-index: 10;
            outline: 2px solid $green;
            outline-offset: -2px;
            transition-duration: .35s;

            &:hover{
                background-size: 105%;
            }
        }

        .merch-big{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: $grid-block-size * 10;
            height: $grid-block-size * 10;
            background-color: $white;
            z-index: 10;
            outline: 2px solid $green;
            outline-offset: -2px;
            transition-duration: .35s;

            &:hover{
                background-size: 105%;
            }
        }

        .merch-pos-1{
            top: $grid-block-size * 3;
            left: 0;
            background-image: url('../photos/merch_25_2.jpg');
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .merch-pos-2{
            left: $grid-block-size * 7;
            top: 0;
            background-image: url('../photos/merch_25_1.jpg');
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .merch-pos-3{
            left: $grid-block-size * 2;
            top: $grid-block-size * 11;
            background-image: url('../photos/merch_25_3.jpg');
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .merch-pos-4{
            left: $grid-block-size * 9;
            top: $grid-block-size * 12;
            background-image: url('../photos/merch_25_4.jpg');
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .merch-cta{
            position: absolute;
            bottom: 0;
            right: 0;
            width: $grid-block-size * 2;
            height: $grid-block-size * 2;
            background-color: $green;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
            cursor: pointer;
            z-index: 100;

            .icon{
                width: 30px;
                height: 30px;
                color: $purple;
            }
        }

        .merch-behind{
            position: relative;
            width: $grid-block-size * 15;
            height: $grid-block-size * 10;
            background-color: $gray;
            margin-top: $grid-block-size * 4;
            margin-left: $grid-block-size * 1;
            z-index: 5;
        }
    }

    .merch-photos-container{
        position: relative;
        width: $grid-block-size * 11;
        margin-top: -$grid-block-size * 2;
        z-index: 10;
        
        .merch-headline{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: $grid-block-size * 4;
            width: 100%;

            h3{
                align-self: start;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 200;
                font-size: $font-paragraph-size;
                text-align: center;
                line-height: 1;
                color: $purple;
                align-self: flex-start;
                padding-left: $grid-block-size;
            }

            h3.strong{
                align-self: start;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
                font-size: $font-paragraph-size;
                text-align: center;
                line-height: 1;
                color: $red;
                align-self: flex-end;
                padding-top:10px;
                padding-right: $grid-block-size;
            }
        }

        .merch-photos{
            position: relative;

            img.photo-1{
                z-index: 5;
                position: relative;
                margin-left: $grid-block-size * 2;
                margin-top: 0;
                rotate: -5deg;
            }

            img.photo-2{
                z-index: 10;
                position: relative;
                margin-left: -10px;
                margin-top: - $grid-block-size * 2;
                rotate: 8deg;
            }

            img.photo-3{
                z-index: 15;
                position: relative;
                margin-left: $grid-block-size * 4;
                margin-top: -30px;
                rotate: 4deg;
            }
        }
    }
}

// PRODUCTION
.prod-container{
    position: relative;
    display: flex;
    align-items: flex-start;
    min-height: $grid-block-size * 19;
    width: $grid-block-size * 31;
    z-index: 20;
    background-color:$white;

    .prod-side-bar{
        position: absolute;
        width: $grid-block-size;
        min-height: $grid-block-size * 19;
        background-color: $green;
        top: 0;
        left: 0;
    }

    // TITLE LAST VIDEO
    .prod-side-title{
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $purple;
        height: $grid-block-size * 2;
        width: $grid-block-size * 6;
        z-index: 60;
       
            h3{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 200;
                font-size: $font-paragraph-size;
                text-align: center;
                color: $white;
            }
    }

    .prod-embed-youtube{ // embed youtube
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: $grid-block-size * 15;
        height: auto;
        margin-top: $grid-block-size * 3;
        margin-left: $grid-block-size * 3;
        background-color: $whiteDarker;
        z-index: 50;
        

        iframe{
            position: relative;
            width: $grid-block-size * 15;
            height: $grid-block-size * 8;
            margin:0;
            overflow:hidden;
        }

    
        h2{
            position: relative;
            text-align: center;
            font-size: $font-large-paragraph-size;
            color: $red;
            padding: 0 $grid-block-size * 2;
            margin-top: $font-paragraph-size * 2;
            margin-bottom: 0;
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 700;
        }
    
        p{
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 200;
            font-size: $font-paragraph-size;
            text-align: center;
            line-height: 1;
            color: $purple;
            padding-top: $font-paragraph-size * 2;
            padding-bottom: $font-paragraph-size;
            padding-left: $grid-block-size * 1;
            padding-right: $grid-block-size * 1;
    
            span{
                color: $red;
                font-weight: 400;
            }
        }

        .button-more-videos{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: $grid-block-size * 2;
            height: $grid-block-size * 2;
            top:  0;
            right: - $grid-block-size * 2;
            background-color: $red;
            cursor: pointer;

            .icon{
                width: 30px;
                height: 30px;
                color: $white;
            }
        }
    }

    .prod-featured-videos{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: $grid-block-size;
        margin-top: $grid-block-size * 6;
        width: $grid-block-size * 8;
        height: auto;
        z-index: 100;

        .video-thumb{
            position: relative;
            margin-top: $grid-block-size;
            width: 100%;
            height: auto;
            background-color: transparent;
            
            &:first-child{
                margin-top: 0;
            }

            iframe{
                position: relative;
                width: $grid-block-size * 8;
                height: $grid-block-size * 5;
                margin:0;
                overflow:hidden;
            }
        }
    }

    .prod-featured-pos-2{
        margin-left: $grid-block-size * 3;
    }
}

.prod-behind{
    position: absolute;
    top: 0;
    left: 0;
    min-height: $grid-block-size * 20;
    width: $grid-block-size * 31;
    z-index: 5;
    background-color:$red;
}

.footer-container{
    z-index: 10;
    position: relative;
    display: flex;
    align-items: flex-start;
    width: $grid-block-size * 32;
    height: $grid-block-size * 12;
    z-index: 10;

    .footer-contact{
        position: relative;
        width: $grid-block-size * 16;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $red;
        background-image: url('../photos/team_footer_1.jpg');
        background-size: cover;
        background-position: 35% center;
        background-repeat: no-repeat;

        .contact-content{

            opacity: 1;

            p{
                text-align: center;
                font-size: $font-paragraph-size;
                color: $white;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
            }
    
            a{
                display: block;
                margin-top: $grid-block-size;
                background-color: transparent;
                text-align: center;
                font-size: $font-small-paragraph-size;
                color: $white;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 700;
                text-decoration: none;
                cursor: pointer;
                transition-duration: .25s;
    
                &:hover{
                    color: $green;
                }
            }
        }

        .footer-logo{
            height: $grid-block-size * 2;
            width: $grid-block-size * 8;
            background-color: $white;
            -webkit-mask-image: url('../svg/gw_logo.svg');
            mask-image: url('../svg/gw_logo.svg');
            mask-repeat: no-repeat;
            mask-position: center center;
        }
    }

    .footer-press{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background-color: $purple;
        //background: linear-gradient(90deg, $purple 0%,  $red 100%);
        width: $grid-block-size * 16;
        position: relative;
        height: 100%;

        .press-content {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: $grid-block-size * 13;
            height: 90%;
            padding-left: $grid-block-size;
            padding-right: $grid-block-size;
            border-right: 1px solid lighten($purple, 10%);

            h1{
                font-size: $font-large-paragraph-size;
                color: $red;
                margin-bottom: $grid-block-size;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 700;
            }

            p{  
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 200;
                text-align: center;
                color: $white;
                font-size: $font-small-paragraph-size;
                line-height: 1;
                padding: 0 $grid-block-size;
            }

            .press-cta{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: $grid-block-size * 6;
                height: $grid-block-size * 2;
                background-color: transparent;
                cursor: pointer;
                border: 1px solid $green;
                box-sizing:border-box;
                -webkit-box-sizing:border-box;
                margin-top: $grid-block-size;
                transition-duration: .25s;
    
                span{
                    width: $grid-block-size * 4;
                    align-self: center;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 400;
                    font-size: $font-paragraph-size;
                    text-align: center;
                    color: $green;
                }
    
                .press-cta-icon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: $grid-block-size * 2;
                    height: $grid-block-size * 2;
                    background-color: transparent;
    
                    .icon{
                        width: 40px;
                        height: 40px;
                        color: $green;
                    }
                }

                &:hover{
                    border: 1px solid $whiteDarker;
                    background-color: $whiteDarker;

                    span{
                        color:$purple;
                    }

                    .press-cta-icon{

                        .icon{
                            color: $purple;
                        }
                    }
                }
            }
        }
    }
}

.footer-line{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $white;
    width: $grid-block-size * 30;
    height: $grid-block-size;
    padding-left: $grid-block-size * 2;

    .footer-tiny-logo{
        position: relative;
        height: $font-paragraph-size;
        width: $font-paragraph-size;
        background-color: $gray;
        -webkit-mask-image: url('../svg/gw_logo_notext.svg');
        mask-image: url('../svg/gw_logo_notext.svg');
        mask-repeat: no-repeat;
        mask-position: center center;
    }

    p{
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 400;
        color: $gray;
        font-size: $font-tiny-paragraph-size;
        line-height: 1;

        //CTA MODAL - RODAL MENTIONS LEGALES
        span{
            color: $gray;
            cursor: pointer;
            text-decoration: underline;
            transition-duration: .25s;
            font-weight: 700;

            &:hover{
                color: $green;
            }
        }
    }
}

.policy{
    position: relative;
    height: 100%;
    overflow: auto;

    h5{
        padding: 14px;
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 700;
        color: $red;
        font-size: $font-small-paragraph-size;
    }

    p{
        padding: 0 5px;
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 400;
        text-decoration: none;
        color: $purple;
        font-size: $font-tiny-paragraph-size;
        line-height: 1.2;

        a{
            color: $purple;
            transition-duration: .25s;

            &:hover{
                color: $red;
            }
        }
    }
}
