// FONTS
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;700&display=swap');
// font-family: 'Nunito Sans', sans-serif; // MADE
// font-family: 'Teko', sans-serif; // FOUNDRY

// -- VARIABLES
// - COLORS
$purple: #18102D;
$green: #3EFFC8;
$red: #E25A66;
$white: #FFFFFF;
$whiteDarker: #F5F5F5;
$gray: #D9D9D9;
$dev: orange;

$margin: 30px;

$font-size-xlarge: 24px;
$font-size-large: 20px;
$font-size-default: 14px;
$font-size-small:12px;
$font-size-tiny: 10px;


@mixin font($font-size, $font-weight, $text-align, $color) {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: $font-weight;
    color: $color;
    font-size: $font-size;
    line-height: 1.1;
    text-align: $text-align;
}

.device-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: $white;
    overflow: hidden;
}

.device-header{
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 10vh;
    background: url('../svg/gw_logo.svg');
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: center center;
    background-color: rgba($color: $white, $alpha: .85)
}

.device-intro{
    position: relative;
    margin-top: 80px;
    height: 85vh;
    background-color: $white;
    background: url('../png/gwardian_stand_front_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -10px 250px;

    h1{
        margin: $margin;
        @include font( $font-size-xlarge, 700, center, $red );
        background-color: $white;
    }

    p{
        margin: $margin;
        @include font( $font-size-large, 400, center, $purple );
        background-color: $white;

        span{
            font-weight: 700;
        }
    }
}

.device-pres{
    position: relative;
    background-color: $purple;

    h1{
        margin: $margin;
        @include font( $font-size-xlarge, 700, center, $green );
    }

    p{
        margin: $margin;
        @include font( $font-size-large, 400, center, $whiteDarker );
        
        span{
            font-weight: 700;
        }
    }

    .device-partenaires-logos{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: auto;
        margin-bottom: $margin;

        a{
            width: 38%;
            margin: 8px 6% 0;
            cursor: pointer;
            text-decoration: none;
            outline: none;

            img{
                max-width: 90%;
            }

        }
    }
}

.device-scores {
    position: relative;
    width: 90%;
    margin: $margin auto;
    background-color: $white;

    h3{
        margin-bottom: $margin;
        @include font( $font-size-large, 700, center, $red )
    }

    // SCORE BOARD LINE
    .device-score-line{
        position: relative;
        display: flex;
        height: 10vh;
        width: 100%;
        background-color: $white;
        border-bottom: 1px solid $purple;
        box-sizing:border-box;
        -webkit-box-sizing:border-box;
        transition-duration: .25s;

        &:last-child{
            border-bottom: 0 none;
        }

        &:hover{
            background-color: $green;
        }

        .score-line-logo{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 20%;
            background-color: $purple;
            background-size: 70%;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .apex{
            background-image: url('../png/games_logo_apex.png');
        }
        
        .nba{
            background-image: url('../png/games_logo_nba.png');
        }

        .ft{
            background-image: url('../png/games_logo_fortnite.png');
        }

        .tm{
            background-image: url('../png/games_logo_tm.png');
        }

        .lol{
            background-image: url('../png/games_logo_lol.png');
        }

        .tf{
            background-image: url('../png/games_logo_teamfight.png');
        }

        .r6{
            background-image: url('../png/games_logo_rainbow.png');
        }

        .score-line-info{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 60%;
            padding-left: 4px;

            span{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 700;
                font-size: $font-size-small;
                color: $purple;
                padding-top: 3px;
                padding-left: 4px;
            }

            .date{
                color:$red;
            }
        }

        .score-line-top{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 20%;
            text-align: center;

            span.good{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 700;
                font-size: $font-size-small;
                color: $purple;
                text-transform: uppercase;
            }

            span.bad{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 700;
                font-size: $font-size-small;
                color: $white;
                text-transform: uppercase;
            }
        }

        .good{
            background-color: $green;
        }

        .bad{
            background-color: $red;
        }
    }
}

.device-shop{
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    background-color: $purple;

    h3{
        margin-top: $margin;
        @include font( $font-size-large, 700, center, $white )
    }

    .merch-item{
        position: relative;
        height: 300px;
        width: 300px;
        margin-top: $margin;
        margin-left: auto;
        margin-right: auto;
        background-size: contain;
        background-position: center center;
        background-repeat: repeat;

        &:first-child{
            margin: 0 auto;
        }

        .merch-cta{
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
            bottom: 0;
            right: 0;
            width: 80px;
            height: 80px;
            background-color: $green;

            .icon{
                width: 40px;
                height: 40px;
            }
        }

        &:last-child{
            margin-bottom: $margin;
        }
    }

    .item-1{
        background-image: url('../photos/merch_25_2.jpg');
    }

    .item-2{
        background-image: url('../photos/merch_25_1.jpg');
    }

    .item-3{
        background-image: url('../photos/merch_25_3.jpg');
    }

    .item-4{
        background-image: url('../photos/merch_25_4.jpg');
    }
    
    .cta-shop{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 200px;
        height: 60px;
        background-color: $green;
        margin: $margin auto;

        span{
            @include font( $font-size-large, 700, center, $purple )
        }
    }
}

.device-prod{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    background-color: $whiteDarker;

    h2{
        margin-top: $margin;
        @include font( $font-size-xlarge, 700, center, $red );
    }

    p{
        @include font( $font-size-large, 400, center, $purple);
        margin: $margin;
    }

    h3{
        margin-top: $margin;
        @include font( $font-size-large, 700, center, $purple )
    }

    .video{
        position: relative;
        height: auto;
        width: 90%;

        iframe{
            position: relative;
            margin:0;
            overflow:hidden;
            width: 100%;
            height: 280px;
        }
    }

    .cta-video{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 200px;
        height: 60px;
        background-color: $red;
        margin: $margin auto;

        span{
            @include font( $font-size-large, 700, center, $white )
        }
    }
}

.device-social{
    position: relative;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: $purple;

    .icon-container{
        display: flex;
        width: 90%;
        justify-content: space-around;

        .icon-block{
            width: 10%;

            &.outline{ // class utile a l'icon 'Threads' diffférente du lot initial
                border: 2px solid $gray;
                border-radius: 50%;
                box-sizing: border-box;

                &:hover{
                    border-color: $green;
                }
                .icon{
                    margin: 6px
                }
            }
            
            .icon div svg{
                fill: $white;
            }
        }
    }
}

.device-contact{
    position: relative;
    width: 100%;
    height: 56vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $gray;
    background-image: url('../photos/team_footer_1.jpg');
    background-size: cover;
    background-position: 35% center;
    background-repeat: no-repeat;

    p{
        @include font($font-size-default, 400, center, $white )
    }

    a{
        @include font($font-size-default, 400, center, $red );
        margin-top: $margin;
    }

    .device-contact-logo{
        height: 100px;
        width: 70%;
        //margin: $margin auto;
        background-color: $white;
        -webkit-mask-image: url('../svg/gw_logo.svg');
        mask-image: url('../svg/gw_logo.svg');
        mask-repeat: no-repeat;
        mask-position: center center;
    }
}

.device-footer{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $purple;
    width: 100%;
    height: 40px;
    padding: 10px;

    p{
        padding: 5px 20px;
        @include font($font-size-tiny, 400, center, $white );

        span{
            text-decoration:underline;
        }
    }
}

.device-policy{
    position: relative;
    height: 100%;
    overflow: auto;

    h5{
        padding: 5px;
        @include font($font-size-large, 700, center, $red );
    }

    p{
        padding: 0 5px;
        @include font($font-size-small, 400, center, $purple );

        a{
            color: $red;
        }
    }
}
